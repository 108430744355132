const tweens = {
  progress: {
    ease: 'Expo.easeInOut',
    states: {
      set: {
        bottom: {
          width: 0,
        },
        center: {
          width: 0,
        },
        right: {
          width: 0,
        },
        top: {
          width: 0,
        },
      },
      to: {
        common: {
          x: '100%',
          delay: 0.25,
        },
      },
    },
  },
};

export default tweens;
