import Constants from '../../constants';

export const defaultState = 0;

export default (state = defaultState, action) => {
  switch (action.type) {
    case Constants.STAGE_PRELOAD_PROGRESS:
      return action.progress;
    default :
      return state;
  }
};
