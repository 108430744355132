import Constants from '../constants';

export const loaded = () => ({
  type: Constants.STAGE_LOADED,
});

export const preload = manifest => ({
  manifest,
  type: Constants.STAGE_PRELOAD,
});

export const preloadStart = params => ({
  params,
  type: Constants.STAGE_PRELOAD_START,
});

export const preloadProgress = progress => ({
  progress,
  type: Constants.STAGE_PRELOAD_PROGRESS,
});

export const preloadSuccess = response => ({
  response,
  type: Constants.STAGE_PRELOAD_SUCCESS,
});

export const preloadError = error => ({
  error,
  type: Constants.STAGE_PRELOAD_ERROR,
});

export const preloadFinish = () => ({
  type: Constants.STAGE_PRELOAD_FINISH,
});
