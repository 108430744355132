import { TweenMax } from 'gsap/TweenMax';

const { URL } = window;
const completeCall = target => TweenMax.set(target, { clearProps: 'position' });

export const onEnter = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 1, {
    autoAlpha: 0,
  });

  TweenMax.to(node, 2, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
    onComplete: completeCall,
    onCompleteParams: [node],
  });
};

export const onExit = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 2, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
  });

  TweenMax.to(node, 1, {
    autoAlpha: 0,
    ease: 'Expo.easeInOut',
  });
};

export const blobToUrl = blobs =>
  Object.entries(blobs).reduce((acc, [key, item]) => {
    const url = URL.createObjectURL(item);

    acc[key] = { url };

    return acc;
  }, {});
