import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';

import * as Actions from '../../actions';
import tweens from '../../constants/tweens';

class Progress extends PureComponent {
  static defaultProps = {
    className: 'Progress',
  };

  static propTypes = {
    className: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handlerComplete = this.handlerComplete.bind(this);
    this.handlerLoaded = this.handlerLoaded.bind(this);
  }

  componentDidMount() {
    TweenMax.set(this.center, tweens.progress.states.set.center);
    TweenMax.set(this.top, tweens.progress.states.set.top);
    TweenMax.set(this.right, tweens.progress.states.set.right);
  }

  componentWillReceiveProps(nextProps) {
    this.handlerProgress(nextProps.progress);
  }

  handlerProgress(progress) {
    const width = `${progress * 100}%`;
    const onComplete = progress === 1 ? this.handlerComplete : () => {};
    const { ease } = tweens.progress;

    TweenMax.to(this.center, 1, { ease, onComplete, width });
    TweenMax.to(this.top, 0.85, { ease, width });
    TweenMax.to(this.right, 0.85, { ease, width });
  }

  handlerComplete() {
    const onComplete = this.handlerLoaded;
    const { ease } = tweens.progress;
    const { delay } = tweens.progress.states.to.common;
    const { x } = tweens.progress.states.to.common;

    TweenMax.to(this.center, 0.75, { delay, ease, onComplete, x });
    TweenMax.to(this.top, 0.70, { delay, ease, x });
    TweenMax.to(this.right, 0.70, { delay, ease, x });
  }

  handlerLoaded() {
    const { dispatch } = this.props;

    dispatch(Actions.loaded());
  }

  render() {
    const { className, progress } = this.props;
    const value = progress * 100;

    return (
      <div
        className={className}
      >
        <div className={'top-progress'}>
          <div
            className={'top-progress-bar'}
            ref={(node) => { this.top = node; }}
          />
        </div>
        <div className={'center-progress'}>
          <div
            className={'center-progress-bar'}
            ref={(node) => { this.center = node; }}
          />
        </div>
        <div className={'right-progress'}>
          <div
            className={'right-progress-bar'}
            ref={(node) => { this.right = node; }}
          />
        </div>
        <div className={'bottom-progress'}>
          <div
            className={'bottom-progress-bar'}
            ref={(node) => { this.bottom = node; }}
          >
            Loading {value} %
          </div>
        </div>
      </div>
    );
  }
}

const mapsStateToProps = state => ({
  progress: state.preloadProgress,
});

export default connect(mapsStateToProps)(Progress);
