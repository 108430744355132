import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Actions from '../../actions';
import Progress from '../progress';
import manifest from '../../../../static/data/manifest.json';

class Preload extends Component {
  static defaultProps = {
    className: 'Preload',
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    loaded: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    props.dispatch(Actions.preload(manifest.url));
  }

  render() {
    const { className, children, loaded } = this.props;

    if (loaded) {
      return children;
    }

    return (
      <div className={className}>
        <Progress />
      </div>
    );
  }
}

const mapsStateToProps = state => ({
  loaded: state.preloadLoaded,
  manifest: state.preloadManifest,
});

export default connect(mapsStateToProps)(Preload);
