import Constants from '../../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case Constants.STAGE_PRELOAD_SUCCESS:
      return action.response;
    default :
      return state;
  }
};
