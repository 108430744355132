import zipObject from 'lodash/zipObject';

const Constants = [
  'STAGE_CHANGE',
  'STAGE_LOADED',
  'STAGE_PRELOAD',
  'STAGE_PRELOAD_ERROR',
  'STAGE_PRELOAD_FINISH',
  'STAGE_PRELOAD_PROGRESS',
  'STAGE_PRELOAD_START',
  'STAGE_PRELOAD_SUCCESS',
];

export default zipObject(Constants, Constants);
