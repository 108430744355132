import { takeLatest } from 'redux-saga/effects';

import * as Stage from './stage';
import Constants from '../constants';

export default (middleware) => {
  middleware.run(function* registerMiddlewares() {
    yield takeLatest(Constants.STAGE_PRELOAD, Stage.preload);
  });
};
