import { call, fork, put, take } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import Api from '../api';
import * as Actions from '../actions';
import * as Utils from '../utils';

export const progressChannel = (api, payload) => {
  let emit;

  const channel = eventChannel((emitter) => {
    emit = emitter;

    return () => {};
  });
  const response = api(payload, (progress) => {
    emit(progress);

    if (progress === 1) {
      emit(END);
    }
  });

  return [channel, response];
};

export function* watchProgressChannel(channel) {
  while (true) {
    const progress = yield take(channel);

    yield put(Actions.preloadProgress(progress));
  }
}

export function* preload(params) {
  const [channel, promise] = progressChannel(Api.preload, params.manifest);
  yield fork(watchProgressChannel, channel);

  try {
    yield put(Actions.preloadStart(params));
    const responses = yield call(() => promise);
    const response = Utils.blobToUrl(responses);

    yield put(Actions.preloadSuccess(response));
  } catch (e) {
    yield put(Actions.preloadError(e.message));
  } finally {
    yield put(Actions.preloadFinish());
  }
}
