import load from 'load-asset';

const Api = {
  preload: (params, onProgress) => {
    const manifest = {
      '/': { url: '/static/media/1.jpeg', type: 'blob' },
      '/404': { url: '/static/media/4.jpeg', type: 'blob' },
      '/studio': { url: '/static/media/2.jpeg', type: 'blob' },
      '/work': { url: '/static/media/3.jpeg', type: 'blob' },
      '/work/foo': { url: '/static/media/a.mp4', type: 'blob' },
    };

    return new Promise((resolve, reject) => {
      load
        .any(manifest, response => onProgress(response.progress))
        .then(assets => resolve(assets))
        .catch(error => reject(error));
    });
  },
};

export default Api;

