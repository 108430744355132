import Constants from '../../constants';

export default (state = false, action) => {
  switch (action.type) {
    case Constants.STAGE_LOADED:
      return true;
    default :
      return state;
  }
};
