import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { connect } from 'react-redux';

import { onEnter, onExit } from '../../utils';

class Canvas extends PureComponent {
  static defaultProps = {
    className: 'Canvas',
  };

  static propTypes = {
    assets: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  };

  render() {
    const { className, assets, section } = this.props;
    const asset = assets[section] || assets['/404'];

    return (
      <TransitionGroup className={className}>
        <Transition
          appear
          key={section}
          onEnter={onEnter}
          onExit={onExit}
          timeout={10000}
          unmountOnExit
        >
          <img alt={asset.url} src={asset.url} />
        </Transition>
      </TransitionGroup>
    );
  }
}

const mapsStateToProps = state => ({
  assets: state.preloadAssets,
});

export default connect(mapsStateToProps)(Canvas);
