import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';

import Home from '../components/home';
import Layout from '../components/layout';
import NotFound from '../components/not-found';
import Studio from '../components/studio';
import Work from '../components/work';

const Routes = ({ location }) => (
  <Layout location={location}>
    <Switch location={location}>
      <Route exact path={'/'} component={Home} />
      <Route exact path={'/studio/'} component={Studio} />
      <Route exact path={'/work/'} component={Work} />
      <Route component={NotFound} />
    </Switch>
  </Layout>
);


Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Routes);
