import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Home extends Component {
  static defaultProps = {
    className: 'Home',
  };

  static propTypes = {
    className: PropTypes.string,
  };

  componentWillAppear() {
    console.log('foo');
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        Home
      </div>
    );
  }
}

export default Home;
