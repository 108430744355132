import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { TweenMax } from 'gsap/TweenMax';

const completeCall = target => TweenMax.set(target, { clearProps: 'position' });
// TODO JSON
const onEnter = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 1, {
    autoAlpha: 0,
    y: 100,
  });

  TweenMax.to(node, 1, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
    onComplete: completeCall,
    onCompleteParams: [node],
    y: 0,
    delay: 250,
  });
};

const onExit = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 1, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
    y: 0,
  });

  TweenMax.to(node, 1, {
    autoAlpha: 0,
    ease: 'Expo.easeInOut',
    y: 100,
  });
};


class NotFound extends Component {
  static defaultProps = {
    className: 'NotFound',
  };

  static propTypes = {
    className: PropTypes.string,
  };

  componentWillAppear() {
    console.log('foo');
  }

  render() {
    const { className } = this.props;

    return (
      <TransitionGroup className={className}>
        <Transition
          appear
          key={'title'}
          onEnter={onEnter}
          onExit={onExit}
          timeout={1000}
          unmountOnExit
        >
          <div className={'title'}>
            Not Found
          </div>
        </Transition>
      </TransitionGroup>
    );
  }
}

export default NotFound;
