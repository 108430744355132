import Constants from '../../constants';

export const defaultState = {
  section: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Constants.STAGE_CHANGE:
      return {
        ...state,
        section: 1
      };
    default:
      return state;
  }
};
