import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from '../header';
import Canvas from '../canvas';

class Main extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'Main',
  };

  render() {
    const { className, children } = this.props;

    return (
      <div className={className}>
        <Header />
        {children}
        <Canvas section={location.pathname} />
      </div>
    );
  }
}

export default Main;
