import PropTypes from 'prop-types';
import React from 'react';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import TweenMax from 'gsap/TweenMax';

const completeCall = target => TweenMax.set(target, { clearProps: 'position' });

// TODO JSON
const onEnter = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 1, {
    autoAlpha: 0,
    y: 100,
  });

  TweenMax.to(node, 1, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
    onComplete: completeCall,
    onCompleteParams: [node],
    y: 0,
  });
};

const onExit = (node) => {
  TweenMax.killTweensOf(node);

  TweenMax.from(node, 1, {
    autoAlpha: 1,
    ease: 'Expo.easeInOut',
    y: 0,
  });

  TweenMax.to(node, 1, {
    autoAlpha: 0,
    ease: 'Expo.easeInOut',
    y: 100,
  });
};

const Layout = ({ children, location }) => (
  <TransitionGroup className={'layout'}>
    <Transition
      appear
      key={location.pathname}
      onEnter={onEnter}
      onExit={onExit}
      timeout={10000}
      unmountOnExit
    >
      {children}
    </Transition>
  </TransitionGroup>
);


Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
