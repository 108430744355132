import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import Store from './store';
import Routes from './routes';
import Main from './components/main';
import Preload from './components/preload';
import * as serviceWorker from './utils/serviceWorker';

const stage = document.body.appendChild(document.createElement('div'));

const App = () => (
  <Provider store={Store}>
    <Preload>
      <BrowserRouter>
        <Main>
          <Route render={Routes} />
        </Main>
      </BrowserRouter>
    </Preload>
  </Provider>
);

ReactDOM.render(
  <App />,
  stage,
);

serviceWorker.register();
