import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Home extends Component {
  static defaultProps = {
    className: 'Studio',
  };

  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        Studio
      </div>
    );
  }
}

export default Home;
